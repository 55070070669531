import Navbar from "../components/Navbar";
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import landing_image from '../assets/img/landing_image.jpg'
import avatar from '../assets/img/avaatar.png'
import suleiman from '../assets/img/suleiman.jpeg'
import ahmed from '../assets/img/ahmed.jpeg'
import gharib from '../assets/img/gharib.jpg'
import khadija from "../assets/img/khadija.jpeg"
import mohamed from '../assets/img/mohamed.jpeg'
import { TweenMax, TimelineMax, Power3, Power4 } from "gsap";
import { useRef, useEffect } from "react";
import Footer from "../components/Footer";



const Directors = () => {


    let screen = useRef(null);
    let body = useRef(null);
    useEffect(() => {
      var tl = new TimelineMax();
      // tl.to(screen, {
      //   duration: 1.5,
      //   height: "100%",
      //   ease: Power3.easeInOut,
      // });
      tl.to(screen, {
        duration: 1.3,
        top: "100%",
        ease: Power3.easeInOut,
        delay: 1,
      });
  
      tl.set(screen, { left: "-100%" });
      tl.remove(screen)
      TweenMax.to(body, .3, {css: {
        opacity: "1",
        pointerEvents: "auto",
        ease: Power4.easeInOut
      }}).delay(2);
      return () => {
        TweenMax.to(body, 1, {css: {
          display:'block',
          pointerEvents: 'none'
        }});
    }
    });


    return ( 
<>
        <div className="load-container">
        <div className="load-screen1 w-full align-center justify-center  flex items-center text-center text-red font-bold text-sm text-green-500 " ref={(el) => (screen = el)}>
            GHARIB HEALTHCARE LIMITED
        </div>
      </div>

            <div className="w-full h-screen border overflow-y-scroll"  ref={(el) => (body = el)} >
                  <Navbar />


                <div className="w-full">
                    <Fade top delay={2000}>
                    <p className="text-3xl">Meet Our Directors</p>
                    </Fade>
                </div>

            <div className="w-full p-2 flex flex-wrap items-center lg:p-5">
                <div className="w-full flex lg:w-1/2">  
                <Fade left delay={2000}>
                <div className="shadow h-full  px-4 py-10 font-gray-500 text-left">
                
    
<p className="text-lg my-2">
Major (Lieutenant Commander) retired Gharib Suleiman
</p>

<p className="text-gray-600">
Gharib Healthcare is run by Major (Lieutenant Commander) retired Gharib Suleiman, formerly of Kenya Navy, and extensively trained both locally at Armed Forces Training College in Lanet and Britannia Royal Naval College, Dartmouth and HMS Plymouth for supplies and senior Administration courses in United Kingdom (UK) . He further holds a diploma in  Hotel and Catering Management and Bachelors Degree in International Studies and Diplomacy with and array of other leadership courses up his sleeves. He is pursuing a Masters Degree in Healthcare Management from the University of Essex. Major Rtd Gharib was also elected as the Councillor Tononoka Ward, and appointed Chairman of Finance and General Purpose Committee (2007-2013), later appointed Director and Chairman of Finance Committee at Coast Water Services Board under the Ministry of Water and Irrigation between 2015 and 2018
</p>

 
                </div>   
</Fade>
                


                </div>

                <div className="w-full lg:w-1/2 px-3">
               <Zoom delay={2000} > 
                    <img src={gharib} alt="gharib suleiman" className="object-cover w-full" />
                </Zoom>
                  </div>

                </div>

                <div className="w-full py-5 px-3 ">
                    <p className="text-lg text-left">
                    Major Gharib is supported by four other Directors who are
                    </p>

                    <div className="w-full flex flex-wrap p-3">
                        <div className="w-full my-2 lg:w-1/4 flex px-2">
                            <div className="w-full shadow-sm border border-green-500 py-3 rounded-lg  ">
                                <div className="w-full flex justify-center  ">
                                    <img src={khadija} alt="avatar" className="rounded-full w-48 h-48 border-2 border-green-500 "  />
                                </div>
                                <div className="my-4 px-3">
                                    <p className="text-center text-gray-500 uppercase">
                                    Doctor Fatma Gharib
                                    </p>
                                    <p className="my-3 font-gray-500 text-left">
                                    Presently serving at Coast General and Referral  Hospital in Mombasa
                                    </p>
                                </div>

                            </div>
                        </div>

                        <div className="w-full my-2 flex lg:w-1/4 px-2">
                            <div className="w-full shadow-sm border border-green-500 py-3 rounded-lg  ">
                                <div className="w-full flex justify-center  ">
                                    <img src={mohamed} alt="avatar" className="rounded-full w-48 h-48 border-2 border-green-500 "  />
                                </div>
                                <div className="my-4 px-3">
                                    <p className="text-center text-gray-500 uppercase">
                                    Suleiman Gharib 
                                    </p>
                                    <p className="my-3 font-gray-500 text-left">
                                    A specialist in healthcare management, about to complete his Degree in Healthcare Management.


                                    </p>
                                </div>

                            </div>
                        </div>

                        
                        <div className="w-full my-2 lg:w-1/4 flex px-2">
                            <div className="w-full shadow-sm border border-green-500 py-3 rounded-lg  ">
                                <div className="w-full flex justify-center  ">
                                    <img src={ahmed} alt="avatar" className="rounded-full w-48 h-48 border-2 border-green-500 "  />
                                </div>
                                <div className="my-4 px-3">
                                    <p className="text-center text-gray-500 uppercase">
                                    Ahmed Gharib 
                                    </p>
                                    <p className="my-3 font-gray-500 text-left">
                                   (Marine And Instrumentation) Engineer.Studied at JKUAT Juja, Kenya	


                                    </p>
                                </div>

                            </div>
                        </div>

                        <div className="w-full my-2 lg:w-1/4  flex px-2">
                            <div className="w-full shadow-sm border border-green-500 py-3 rounded-lg  ">
                                <div className="w-full flex justify-center  ">
                                    <img src={suleiman} alt="avatar" className="rounded-full w-48 h-48 border-2 border-green-500 "  />
                                </div>
                                <div className="my-4 px-3">
                                    <p className="text-center text-gray-500 uppercase">
                                   
                                    Mohamed Gharib
                                    </p>
                                    <p className="my-3 font-gray-500 text-left">
                                    Qualified Accountant and Finance Manager currently based in Sultanate Oman.

                                    </p>
                                   
                                </div>

                            </div>
                        </div>



                     
                        
                    </div>

                    

                </div>

            <div className="w-full">
<Footer />
            </div>

            </div>
            </>
        );
}
 
export default Directors;