import Fade from 'react-reveal/Fade';
import instagram from '../assets/img/instagram.png'
import twitter from '../assets/img/twitter.png'
import facebook from '../assets/img/facebook.png'
const Footer = ()=> {
    return (
        <Fade bottom>
        <div className="w-full footerBlock h-72 bg-gray-100 py-7">
                <div className="mx-auto w-full text-3xl">Contact Us</div>

                <div className="w-full flex flex-wrap my-6">
                    <div className="w-full lg:w-1/2 text-left px-3 lg:px-5">
                        <p className="font-bold text-gray-500 my-2" >Phone: +254 722 483058</p>
                        <p className="font-bold text-gray-500 my-2" >Email: info@gharibhealthcare.com</p>
                        <p className="font-bold text-gray-500 my-2"><span className="material-icons outlined mr-2 inline-flex content-end">location_on</span>Tom Mboya Avenue, Mombasa</p>
                        <p className="font-bold text-gray-500 my-2" >P. O. Box 98082 80100</p>
                        <p className="font-bold text-gray-500 my-2" >www.gharibhealthcare.com</p>

                    </div>
                <div className="w-full lg:w-1/2 flex flex-col  text-left text-right p-5 align-end">
                <p className="font-bold text-gray-500 my-2" >Gharib HealthCare Limited</p>
                {/* <p>Text</p> */}
                <div className="flex text-right w-full  justify-end my-4 ">
                    <p className="mx-2">
                        <a href="https://instagram.com"><img src={instagram} alt="" /></a>
                    </p>
                    <p className="mx-2">
                        <a href="https://facebook.com"><img src={facebook} alt="" /></a>
                    </p>
                    <p className="mx-2">
                        <a href="https://twitter.com"><img src={twitter} alt="" /></a>
                    </p>
                </div>

                <p className="my-3 text-gray-500">&copy; 2021</p>

                </div>
                </div>

        </div>
        </Fade>
    )
}   


export default Footer