import logo from './logo.svg';
import './App.css';
import './index.css'
import { BrowserRouter as Router,Route,Switch } from 'react-router-dom'
import Landing from './pages/Landing';
import Objectives from './pages/Objectives'
import Directors from './pages/Directors';


function App() {
  return (
    <Router>
    <div className="App">
      <Switch>
      <Route exact path="/">
      <Landing />
      </Route>
      <Route exact path="/objectives">
       <Objectives />
      </Route>

      <Route exact path="/directors">
       <Directors />
      </Route>
      </Switch>
    </div>
    </Router>
  );
}

export default App;
