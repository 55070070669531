import Navbar from "../components/Navbar";
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import landing_image from '../assets/img/landing_image.jpg'
import { Link } from "react-router-dom";
import { TweenMax, TimelineMax, Power3, Power4 } from "gsap";
import { useRef, useEffect } from "react";
import Footer from "../components/Footer";

const Landing = () => {


    let screen = useRef(null);
    let body = useRef(null);
    useEffect(() => {
      var tl = new TimelineMax();
      // tl.to(screen, {
      //   duration: 1.5,
      //   height: "100%",
      //   ease: Power3.easeInOut,
      // });
      tl.to(screen, {
        duration: 1.3,
        top: "100%",
        ease: Power3.easeInOut,
        delay: 1,
      });
  
      tl.set(screen, { left: "-100%" });
      tl.remove(screen)
      TweenMax.to(body, .3, {css: {
        opacity: "1",
        pointerEvents: "auto",
        ease: Power4.easeInOut
      }}).delay(2);
      return () => {
        TweenMax.to(body, 1, {css: {
          display:'block',
          pointerEvents: 'none'
        }});
    }
    });


    return ( 
<>
        <div className="load-container">
        <div className="load-screen1 w-full align-center justify-center  flex items-center text-center text-red font-bold text-sm text-green-500 " ref={(el) => (screen = el)}>
            GHARIB HEALTHCARE LIMITED
        </div>
      </div>

            <div className="w-full h-screen border overflow-y-scroll"  ref={(el) => (body = el)} >
                  <Navbar />
                <div className="flex flex-wrap h-full p-3  lg:p-7">
                    <div className="w-full  lg:w-1/2 h-full flex  text-left  items-center">
                    
                    <div className="">
                    <Fade left cascade delay={1700}>
                    <h1 className="font-bold text-3xl lg:text-6xl ml-2 w-full">
                        <p className="py-3">Improving Access To</p>
                        <p className="py-3"><span className="borderedText">Quality, </span> <span className="borderedText">Affordable</span> <span className="borderedText"> & Innovative</span></p>
                        <p className="py-3">Healthcare Services </p>
                    </h1>
                    </Fade>
                    <Fade left delay={2000} >
                <Link to="/objectives" ><p className="ml-2 mt-6 text-green-500 font-bold" >Learn More</p></Link>
                </Fade>
                    </div>
                   

                    
                    </div>

                    

                    <div className="w-full  flex h-full lg:w-1/2 items-center p-2 lg:p-4">

                    {/* <svg class="g-h-swoop-image" role="presentation" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 361.132 878.941"><path data-name="New Swoop 01" d="M360.47.98s-808.514 163.746 0 877.211" fill="none" stroke="red" stroke-miterlimit="10" stroke-width="2"></path></svg>

             */}

                    <div class="overflow-hidden rounded-3xl">
                        <Zoom delay={2000}>
                        <div className="">                     
                        <img src={landing_image} className="rounded-3xl lg:mt-3 transition-all duration-500 ease-in-out transform hover:scale-150" alt="Logi" />  
                        </div>                
                        </Zoom>
                        </div>
                    </div>


                </div>

            <div className="my-6">


            <svg class="g-h-swoop-image g-h-swoop-image--full" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1471.221 157.845"><path vector-effect="non-scaling-stroke" d="M.382 143.081S532.097-77.03 1096.437 30.671c124.422 23.745 251.346 63.887 374.331 126.282" fill="none" stroke="#10B981" stroke-miterlimit="10" stroke-width="2"></path></svg>


          <div className="full text-center lg:w-2/3  mx-auto">
          <p className="text-xl text-3xl -mt-10">
      Gharib Healthcare Key Objectives
              </p>  
              <p className="text-gray-600 my-4">
              Gharib Healthcare will be impacting the healthcare landscape through collaborative approaches that help address Mombasa County and the Regions greatest needs, those of health disparities and diagnostic excellence and patient care
                </p>          
          </div>


          <div className="w-full px-3 lg:px-6 my-4 mx-auto flex flex-wrap mt-10">

            <Fade bottom cascade >
            <div className="w-full lg:w-1/3 px-3   ">
              <div className="w-full bg-blue-200 px-3 h-full lg:px-7  py-16 ">
            <div className="w-20 h-20 rounded-full bg-white  flex justify-center items-center text-blue-600">
            <span class="material-icons-outlined font-bold text-2xl">
remove_red_eye
</span>
            </div>

            <div className="mb-3 mt-4 text-xl text-blue-600 text-left font-bold uppercase">Our Vision</div>
            <p className="text-blue-600 my-4 text-left">
            To enhance the lives of all residents of Mombasa and neighbouring Counties, and those who visit Mombasa through improved access to quality and affordable and innovative healthcare services.

            </p>


            </div>

            </div>


            <div className="w-full lg:w-1/3 px-3">
            <div className="w-full bg-green-200 px-3 lg:px-7 h-full  py-16">

            <div className="w-20 h-20 rounded-full bg-white  flex justify-center items-center text-green-600">
            <span class="material-icons-outlined font-bold text-2xl">
            <span class="material-icons-outlined">
trending_up
</span>

</span>
            </div>

            <div className="mb-3 mt-4 text-xl text-green-600 text-left font-bold uppercase">OUR MISSION STATEMENT </div>
            <p className="text-green-600 my-4 text-left">
            Gharib Healthcare is in business to establish a first class 150-bed facility that will take care of both highly placed clients and lowly placed clients as long as they can afford our services. We want to become one of the leaders in the hospital cum health care services industry in Mombasa County, and in Kenya.
            </p>


</div>

            </div>

            <div className="w-full lg:w-1/3 px-3 ">
            <div className="w-full px-3 lg:px-7 h-full  py-16 bg-yellow-200">

            <div className="w-20 h-20 rounded-full bg-white  flex justify-center items-center text-yellow-600">
            <span class="material-icons-outlined font-bold text-2xl">
           
thumb_up
</span>
            </div>

            <div className="mb-3 mt-4 text-xl text-yellow-600 text-left font-bold uppercase">OUR VALUES</div>
            <p className="text-yellow-600 my-4 text-left">
              <p className="my-1">
              a. We strive to further enhance trust between patients, doctors, and the medical fraternity.
              </p>
              <p className="my-1">
              b. We lead by example, setting high standards for ourselves and for the doctors.
              </p>
              <div className="py-1">
              c. We act in a respective, fair, empathetic, and consistent manner
              </div>
              <div className="py-1">
              d. We make independent, informed, and objective decisions and we are accountable for them. 
              </div>
              <div className="py-1">
                e. We encourage diversity, engagement and learning to help us be a better healthcare provider
              </div>
            </p>





            </div>
            </div>


            </Fade>

          </div>

         


            </div>

            <div className="w-full">
<Footer />
            </div>

            </div>
            </>
        );
}
 
export default Landing;