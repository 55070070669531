import Fade from 'react-reveal/Fade';
import { Link } from 'react-router-dom';
import ghl from "../assets/img/ghl.png"
import simba from "../assets/img/S.png"
const Navbar = () => {
    return ( 
<Fade top  cascade delay={2000}>
<nav className="">
  <div className="mx-auto px-2 sm:px-3 bg-white  lg:px-5 ">
    <div className="relative flex items-center justify-between h-16">

      <div className="flex-1 flex items-center justify-center  sm:justify-start">
        {/* <div className="flex-shrink-0 flex items-center"> */}
          {/* <img className="hidden lg:block h-8 w-auto" src="https://tailwindui.com/img/logos/workflow-logo-indigo-500-mark-white-text.svg" alt="Workflow" />  */}
          <img className="object-contain h-32  -mb-9 mt-6 " src={ghl} alt="Logo" /> 
          <p class="-ml-2" >Gharib Health Care</p>
        {/* </div> */}
        <div className="hidden sm:block sm:ml-10">
          <div className="flex space-x-9 text-gray-500 text-sm ">
          
            <Link to="/" className="">About Us</Link>

            <Link to="/objectives" className="">Key Objectives</Link>

            <Link to="/directors" className="">Directors</Link>
          </div>
        </div>
        <div className="ml-auto">
        <img className="object-contain  -mb-9 h-32 mt-6  mr-4" src={simba} alt="Logo" /> 
        </div>
      </div>

    </div>
  </div>

</nav>
</Fade>


     );
}
 
export default Navbar;