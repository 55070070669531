import React from "react";
import { NavLink } from "react-router-dom";
import { TweenMax, TimelineMax, Power3, Power4 } from "gsap";
import { useRef, useEffect } from "react";
import Navbar from "../components/Navbar";
import lab from "../assets/img/lab.jpg"
import social from "../assets/img/social.jpg"
import equipments from "../assets/img/equipments.jpg"
import Fade from 'react-reveal/Fade';
import saving from "../assets/img/saving.jpg"
import schoolChild from "../assets/img/school-children.jpg"
import plans from "../assets/img/plans.jpg"
import allLives from "../assets/img/allLives.jpg"
import modern from "../assets/img/modern.jpg"
import gov from "../assets/img/government.jpg"
import privateImg from "../assets/img/private.jpg"

import simba from "../assets/img/simba.jpg"
import Footer from "../components/Footer";

const Objectives = () => {

  let screen = useRef(null);
  let body = useRef(null);
  useEffect(() => {
    var tl = new TimelineMax();
    // tl.to(screen, {
    //   duration: 1.5,
    //   height: "100%",
    //   ease: Power3.easeInOut,
    // });
    tl.to(screen, {
      duration: 1.3,
      top: "100%",
      ease: Power3.easeInOut,
      delay: 1,
    });

    tl.set(screen, { left: "-100%" });
    tl.remove(screen)
    TweenMax.to(body, .3, {css: {
      opacity: "1",
      pointerEvents: "auto",
      ease: Power4.easeInOut
    }}).delay(2);
    return () => {
      TweenMax.to(body, 1, {css: {
        display:'block',
        pointerEvents: 'none'
      }});
  }
  });

  return ( 
    <>
        <div className="load-container">
        <div className="load-screen1 w-full align-center justify-center  flex items-center text-center text-red font-bold text-sm text-gray-400 " ref={(el) => (screen = el)}>
            GHARIB HEALTHCARE LIMITED
        </div>
      </div>

            <div className="w-full h-screen border overflow-y-scroll"  ref={(el) => (body = el)} >
                <Navbar />
      <Fade top delay={2000}>
              <div className="w-full lg:w-1/2 mx-auto text-center lg:py-36">
              <p className="text-5xl" >Committed to significantly improving the lives of as many people as possible.</p>
              <p className="my-6 text-lg text-gray-500">Our Key Objective as Gharib Healthcare  is as follows:</p>
              </div>
              </Fade>

              <div className="w-full lg:w-5/6 mx-auto">
               


                  {/* sec one */}
<div className="flex w-full flex-wrap p-2 lg:p-6 ">

    <Fade left delay={2000}>
                <div className="w-full lg:w-1/2 text-left lg:pr-4">
                  <p className="font-normal text-3xl">What We Do For Others</p>
                  <p className="text-gray-500 my-3">
                  Gharib Healthcare concerns herself in the surveying,research,analysis and consultancy works mitigating on certain factors so as to:
            <ol>
              <li>i. Increase access to healthcare for the communities along the Kenya Coast Line</li>
              <li>ii. Address health disparities amongst the poor and uninsured adults.
</li>
<li>
iii. Afford the most vulnerable with diagnostic excellence and patient core.
</li>
            </ol>
                  </p>
                </div>
                </Fade>


                <div className="w-full lg:w-1/2 ">
    <Fade right delay={2000}>

                  <div className="rounded">
                  <img src={lab} alt="socail" className="w-full  object-cover object-center rounded-lg shadow-md" />    
                  </div>
</Fade>

                </div>
                
                




</div>

{/* sec two */}

<div className="flex w-full flex-wrap p-2 py-5 lg:p-6 ">

<div className="w-full lg:w-1/2 ">
  <Fade left delay={2000}>
  <div className="rounded">
                  <img src={social} alt="socail" className="w-full  object-cover object-center rounded-lg shadow-md" />    
                  </div>
  </Fade>
                  
      </div>



          <Fade right delay={1000}>
                <div className="w-full lg:w-1/2 text-left lg:pl-6 ">
                  <p className="font-normal text-3xl">Demographic and socio-economic characteristics of the Region</p>
                  <p className="text-gray-500 my-2">
                  All the demographic and socio-economic characteristics for the Kenyan coast describes an area with a growing and increasingly diverse population, a significant proportion of aging adults, and huge pockets of some of the most extreme poverty and low educational attainment in the Country. In the meantime, the Ministry of Health has seen budget cuts over several years, putting further pressure on the health and human services safety net at a time when demand for services continues to grow.
                  </p>
                  <p className="text-gray-500 my-2">
                  Health disparities have been a growing concern throughout the Kenyan Coast and this is the main area that Gharib Healthcare would address. Significant differences remain in health outcomes associated with the poor and with social-economic status indicators such as income and educational level occasioning high levels of unemployment which impact on access to healthcare. 
                  </p>
                </div>
              </Fade>



</div>


{/* sec three */}


<div className="flex w-full flex-wrap p-2 lg:p-6 ">
  <Fade left>
  <div className="w-full lg:w-1/2 text-left lg:pr-4">
                  <p className="font-normal text-3xl">Sufficient capacity does not exist to provide medical care
</p>
                  <p className="text-gray-500 my-3">
                  The fact is that sufficient capacity does not exist to provide medical care to all who need it as evidenced in the difficulties currently experienced in referral process, which result in ‘de-facto’ rationing of care contributing to over-crowding and long waits in County Hospitals, ambulance diversion to other hospitals, and higher overall costs of care. The process of accessing low cost care is simply too complicated and many individuals in need stop looking before they reach the service they need.
                  </p>
                </div>
  </Fade>


                <div className="w-full lg:w-1/2 ">
          <Fade right>
                  <div className="rounded">
                  <img src={equipments} alt="equipments" className="w-full  object-cover object-center rounded-lg shadow-md" />    
                  </div>
              </Fade>
                </div>
                
                




</div>


{/* sec four */}

<div className="flex w-full flex-wrap p-2 py-5 lg:p-6 ">

<div className="w-full lg:w-1/2 ">
  <Fade left delay={2000}>
  <div className="rounded">
                  <img src={allLives} alt="socail" className="w-full  object-cover object-center rounded-lg shadow-md" />    
                  </div>
  </Fade>
                  
      </div>



          <Fade right delay={1000}>
                <div className="w-full lg:w-1/2 text-left lg:pl-6 ">
                  <p className="font-normal text-3xl">All lives have equal values</p>
                  <p className="text-gray-500 my-2">
                  What transpired on an evening in March of 2014 at Doctor Juma’s clinic, a general practitioner in Mombasa, made us  change our perspective on how we help others. I was seated in the waiting area eager for a chance to see the Doctor when I noticed a young man of about 17 years entering the clinic. He went directly to the lab assistance and asked for a blood test for Malaria, a killer disease when not properly treated. What surprised me is that the teenager left the clinic upon receiving his test results. He ignored appeals by the lab assistant to see the doctor. It made me cry when the lab assistant explained the fear harboured by the poor teenager. It is costly to pay doctor’s fee for a prescription. Such events are still a very common occurrence in this area. The young man would probably approach a Pharmacy, and ask to be sold the cheapest anti-malarials, or worse still take herbal concoctions. A very risky affair. 
                  </p>
               
                </div>
              </Fade>



</div>





{/* sec five */}


<div className="flex w-full flex-wrap p-2 lg:p-6 ">
  <Fade left>
  <div className="w-full lg:w-1/2 text-left lg:pr-4">
                  <p className="font-normal text-3xl">Healthcaring is in a SORRY State for the Region
</p>
                  <p className="text-gray-500 my-1">
                  Ghalib Healthcare recognize that diagnostic error, inaccurate, delayed or missed diagnosis will result in delays in treatment, allowing undiagnosed condition to persist or even progress and worsen outcomes. There is not a single Government or private hospital in the whole of Coast Region which is equipped with radiation therapy facility. Cancer patients have to wait for an appointment in Nairobi for up to 8 months. 
                  </p>
                  <p className="text-gray-500 my-1">
                  The cost of MRI and CT Scanners, plus dialysis, are out of reach of most of the poor. Furthermore, the MRI/CT scanner available locally are past their prime due to age and low magnification. The private hospitals are in the business of “maximizing their profits” hence there is no haste to update themselves with the latest technology. These has left many people to travel to India and most recently Turkey for proper diagnosis and treatment. Those travelling abroad for treatment need not to do so when there are affordable proper diagnosis locally.
                  </p>
                </div>
  </Fade>


                <div className="w-full lg:w-1/2 ">
        <Fade right>
                  <div className="rounded">
                  <img src={modern} alt="equipments" className="w-full  object-cover object-center rounded-lg shadow-md" />    
                  </div>
        </Fade>
                </div>
                

</div>


{/* sec six */}





{/* sec six */}


<div className="flex w-full flex-wrap p-2 lg:p-6 ">
<div className="w-full lg:w-1/2 text-left lg:pr-4">

<Fade left>
  <div className="rounded">
                  <img src={schoolChild} alt="equipments" className="w-full  object-cover object-center rounded-lg shadow-md" />    
                  </div>

  </Fade>
  </div>


               
        <Fade right>
        <div className="w-full lg:w-1/2 text-left lg:pr-4">
                  <p className="font-normal text-3xl">Creating Positive Outcomes for future generations
</p>
                  <p className="text-gray-500 my-1">
                  The greatest distinguishing advantages of Gharib Healthcare is its valuable and versatile option for delivering healthcare services and initiating chronic diseases management and education to the uninsured, low income, and geographically or socially isolated residents who would not otherwise have access to healthcare
                  </p>
             
                </div>
        </Fade>
                
                

</div>


{/* sec seven */}



<div className="flex w-full flex-wrap p-2 lg:p-6 ">
  <Fade left>
  <div className="w-full lg:w-1/2 text-left lg:pr-4">
                  <p className="font-normal text-3xl">Saving one person’s life will not change the world but it will change the world forever for that person
</p>
                  <p className="text-gray-500 my-1">
                  Gharib healthcare  are committed to developing programmes that will provide ultramodern treatment  by dissociating healthcare from prosperity with the objective of dignifying the poor and the less privileged members of our society

                  </p>
             
                </div>
  </Fade>


                <div className="w-full lg:w-1/2 ">
        <Fade right>
                  <div className="rounded">
                  <img src={saving} alt="equipments" className="w-full  object-cover object-center rounded-lg shadow-md" />    
                  </div>
        </Fade>
                </div>
                

</div>



<div className="flex w-full flex-wrap p-2 lg:p-6 ">
<div className="w-full lg:w-1/2 text-left lg:pr-4">

<Fade left>
  <div className="rounded">
                  <img src={plans} alt="equipments" className="w-full  object-cover object-center rounded-lg shadow-md" />    
                  </div>

  </Fade>
  </div>


               
        <Fade right>
        <div className="w-full lg:w-1/2 text-left lg:pr-4">
                  <p className="font-normal text-3xl my-1">Future Plans/Way Forward
</p>
                  <p className="text-gray-500 my-1">
                  Data captured during feasibility studies for Gharib Healthcare  shows extraordinary high mobility and mortality rates not only in Mombasa County and the counties along the Kenyan Coast, but in all the other 47 counties across Kenya and across the border into neighbouring towns in Tanzania. There is low level of preventive care options, especially around threats to healthcare that include smoking, abuse of alcohol and drugs, notwithstanding child pregnancies. The region ranks above national average among eight leading causes of deaths including heart disease,chronic lower respiratory diseases, influenza/pneumonia,HIV,diabetes,malaria and suicide.All theses play a role in demand for healthcare.
                  </p>
                  <p className="text-gray-500 my-1">
                  All the demographic and socio-economic characteristics for the Kenyan Coast have been described earlier,Health disparities  have been a growing concern throughout the Kenya coast and this is the main area that Gharib Healthcare would address. Significant differences remain in health outcomes associated with the poor and with socio-economic status indicators such as income and educational level occasioning high levels of unemployment which impact on access to health care.
                  </p>
                  <p className="text-gray-500 my-1">
                  Consequent to above, and strengthered by the data captured during feasibility studies, intensive collaboration with industry personalities and  government officials and further informed by surveys which were conducted by professional in the fields, the Directors of Gharib Healthcare after length and deliberations and giving due regard to concerns above have resolved to construct and equip a state of the art Hospital in Mombasa. In mitigating on the factors that will contribute effectively to increase access to  healthcare and equally address health disparities associated with the poor.
                  </p>
             
                </div>
        </Fade>
                
                

</div>


<div className="flex w-full flex-wrap p-2 lg:p-6 ">
  <Fade left>
  <div className="w-full lg:w-1/2 text-left lg:pr-4">
                  <p className="font-normal text-3xl">Government Position On the Role of Private Healthcare Providers
</p>
                  <p className="text-gray-500 my-1">
            A 2013 external evaluation of the Ministry of Health (MOH) last ten year strategy in Kenya identified several systematic weakness that impede programme towards achieving national health objectives.
                  </p>
                  <p className="text-gray-500 my-1">
                    Key Findings include Local Health systems and social and behavioural change communication (SBCC) interventions do not reach the population especially people who live far from the community healh centres. Key national and local health systems are not able to provide all the inputs (commodities,human resource etc) needed to support health services at the local level and health management information system (HMIS) do not provide acuurate and timely data for informed decision making
                  </p>
                  <div className="text-gray-500 my-1">
                    Based on the epidemiology and conditions in Kenya new interventions have addressed the technical areas of maternal,neonatal and child health, family planning and reproductive health, malaria and infectitious diseases include HIV and neglected tropical diseases (NTDS). The progress towards universal healthcare (UHC) must enhance a scaleup and intensifection of proven approachesto achieve greater impact on national health indicators.
                  </div>
                  <div className="text-gray-500 my-1">
                    The UHC program will include several ways that new interventions can achieve even greater impact, such as a reduced number of partners and better effeciencies consolidated geographic focus and implementation in every county. 
                  </div>
             
                </div>
  </Fade>


                <div className="w-full lg:w-1/2 ">
        <Fade right>
                  <div className="rounded">
                  <img src={gov} alt="equipments" className="w-full  object-cover object-center rounded-lg shadow-md" />    
                  </div>
        </Fade>
                </div>
                

</div>


<div className="flex w-full flex-wrap p-2 lg:p-6 ">
<div className="w-full lg:w-1/2 text-left lg:pr-4">

<Fade left>
  <div className="rounded">
                  <img src={privateImg} alt="equipments" className="w-full  object-cover object-center rounded-lg shadow-md" />    
                  </div>

  </Fade>
  </div>


               
        <Fade right>
        <div className="w-full lg:w-1/2 text-left lg:pr-4">
                  <p className="font-normal text-3xl my-1">Inclusion of Private Sector Providers at The County and Community Level
</p>
                  <p className="text-gray-500 my-1">
              A greater emphasis on local level governance, transparency and accountability , a greater emphasis on health financing. The COVID 19 pandemic over the past year has seen policy change calling upon the private sector health providers to work shoulder to shoulder with the MOH in impacting the healthcare in Kenya.
                  </p>
                  <div className="text-gray-500 my-1">
                    Kenya can do more to impove healthcare outcomes and tackle poverty by increasing coverage of health services and by reducinig the impoverishment associated with costly payments for health services.
                  </div>

                </div>
        </Fade>
                
                

</div>


<div className="flex w-full flex-wrap p-2 lg:p-6 ">
  <Fade left>
  <div className="w-full lg:w-1/2 text-left lg:pr-4">
                  <p className="font-normal text-3xl">The Simba Hospital
</p>
                  <p className="text-gray-500 my-1">
           Simba Hospital is established to provide preventive, curative and promotional rehabilitative or palliative healthcare services in a systematic way to individuals, families and communities. Simba Hospital is a subsidiary company yo Gharib Healthcare and will be impacting the health care landscape through collaborative approaches that help address Mombasa County's and the Region's greatest needs. those of health disparities, diagnostic excellence and patient care.
                  </p>
                  <p className="text-gray-500 my-1">
The Hospital will also seek to be licenced as  a general medical and surgical facility that provide surgical, nonsurgical diagnostic and medical treatment to inpatients with medical conditions. THe hospital will maintain 150 patients beds and provide outpatient services,operating room services and phamarcy services plus oncology.
                  </p>
                  <p className="text-gray-500 my-1">
                    We are in the hospital business to deliver excellent healthcare services to all those who will patronize our facility on a pro-poor policies so as to encourage the poor and uninsured adults to access our facility. We will also ensure that we will comply with the laws and health regulations in Kenya and County Government of Mombasa. 
                  </p>
                  <p className="text-gray-500 my-1">
                    Simba Hospital will operate a 24 Hours, 7 days a week hospital practice service; our hopsital will be opened round the clock to attend to patients. We have a standard call center that is manned by trained health workers. Our employees are going to be well trained to operate within the framework of our organization's corporate culture and also to meet the needs of all our patients.
                  </p>
                  <div className="text-gray-500 my-1">
                    Simba Hospital will ensure that all our patients irrespective of their status in society are given first class treatement whenever they visit our facility.
                  </div>

             
                </div>
  </Fade>


                <div className="w-full lg:w-1/2 ">
        <Fade right>
                  <div className="rounded">
                  <img src={simba} alt="equipments" className="w-full  object-cover object-center rounded-lg shadow-md" />    
                  </div>
        </Fade>
                </div>
                

</div>







                             
              </div>



              <Footer className="mt-4" />
              
            </div>
            </>
   );
}
 
export default Objectives;